<template>
  <div ref="slideElRef" class="swiper-slide" @focusin="handleFocusIn">
    <slot v-bind="slideData" />
  </div>
</template>

<script setup lang="ts">
import { useMousePressed } from '@vueuse/core'
import type Swiper from 'swiper'
import { Ref } from 'vue'

const props = withDefaults(defineProps<{ swiperSlideIndex?: number }>(), {
  swiperSlideIndex: undefined
})

const swiperRef = useRequiredInject<Ref<Swiper>>('swiper')

let eventAttached = false
const slideElRef = ref()
const slideClasses = ref('swiper-slide')

// eslint-disable-next-line max-params
const updateClasses = (swiper: Swiper, el: HTMLElement, classNames: string) => {
  if (el === slideElRef.value) {
    slideClasses.value = classNames
  }
}

onMounted(() => {
  if (!swiperRef || !swiperRef.value) return
  swiperRef.value.on?.('_slideClass', updateClasses)
  eventAttached = true
})
onBeforeUpdate(() => {
  if (eventAttached || !swiperRef || !swiperRef.value) return
  swiperRef.value.on?.('_slideClass', updateClasses)
  eventAttached = true
})
onUpdated(() => {
  if (!slideElRef.value || !swiperRef || !swiperRef.value) return
  if (typeof props.swiperSlideIndex !== 'undefined') {
    slideElRef.value.swiperSlideIndex = props.swiperSlideIndex
  }
  if (swiperRef.value.destroyed) {
    if (slideClasses.value !== 'swiper-slide') {
      slideClasses.value = 'swiper-slide'
    }
  }
})
onBeforeUnmount(() => {
  if (!swiperRef || !swiperRef.value) return
  swiperRef.value.off?.('_slideClass', updateClasses)
})
const slideData = computed(() => ({
  isActive: slideClasses.value.indexOf('swiper-slide-active') >= 0,
  isVisible: slideClasses.value.indexOf('swiper-slide-visible') >= 0,
  isPrev: slideClasses.value.indexOf('swiper-slide-prev') >= 0,
  isNext: slideClasses.value.indexOf('swiper-slide-next') >= 0
}))

const mouse = useMousePressed()

const handleFocusIn = () => {
  if (props.swiperSlideIndex !== undefined && !mouse.pressed.value)
    swiperRef.value.slideTo(props.swiperSlideIndex)
}
</script>

<style lang="scss" scoped></style>
