<template>
  <div
    ref="swiperElm"
    class="swiper"
    :class="{
      'swiper-autoheight': autoHeight,
      'swiper-initialized': initialized
    }"
  >
    <div class="controls">
      <slot name="pagination">
        <div
          v-if="shouldCreateSlot(pagination, 'el')"
          ref="paginationElm"
          class="swiper-pagination"
        />
      </slot>
      <slot name="navigation">
        <div
          v-if="shouldCreateSlot(navigation, ['prevEl', 'nextEl'])"
          class="navigation"
        >
          <div ref="navigationPrevElm" class="swiper-button-prev" />
          <div ref="navigationNextElm" class="swiper-button-next" />
        </div>
      </slot>
    </div>
    <div class="swiper-wrapper">
      <slot :swiper-ref="swiper" />
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css'
import Swiper from 'swiper'
import { Pagination, Autoplay, Keyboard, Navigation } from 'swiper/modules'
import { AutoplayOptions } from 'swiper/types/modules/autoplay'
import { NavigationOptions } from 'swiper/types/modules/navigation'
import { PaginationOptions } from 'swiper/types/modules/pagination'
import { SwiperModule } from 'swiper/types/shared'
import { SwiperOptions } from 'swiper/types/swiper-options'

const props = withDefaults(
  defineProps<{
    effect?: 'slide' | 'fade' | 'cube' | 'coverflow' | 'flip' | 'creative'
    slidesPerView?: number | 'auto'
    spaceBetween?: number | string
    centeredSlides?: boolean
    centeredSlidesBounds?: SwiperOptions['centeredSlidesBounds']
    pagination?: PaginationOptions | boolean
    autoplay?: AutoplayOptions | boolean
    loop?: boolean
    loopedSlides?: number | null
    keyboard?: boolean
    navigation?: NavigationOptions | boolean
    initialSlide?: number
    breakpoints?: SwiperOptions['breakpoints']
    autoHeight?: SwiperOptions['autoHeight']
    slidesOffsetBefore?: SwiperOptions['slidesOffsetBefore']
    slidesOffsetAfter?: SwiperOptions['slidesOffsetAfter']
    noSwiping?: SwiperOptions['noSwiping']
    allowTouchMove?: SwiperOptions['allowTouchMove']
    touchStartPreventDefault?: SwiperOptions['touchStartPreventDefault']
  }>(),
  {
    effect: undefined,
    slidesPerView: 'auto',
    spaceBetween: 0,
    pagination: false,
    autoplay: false,
    keyboard: false,
    navigation: undefined,
    initialSlide: undefined,
    loopedSlides: undefined,
    breakpoints: undefined,
    centeredSlidesBounds: undefined,
    autoHeight: undefined,
    allowTouchMove: true,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
    noSwiping: undefined,
    touchStartPreventDefault: undefined
  }
)

const emit = defineEmits<{
  (e: 'slideChange', value: Swiper): void
  (e: 'init', value: Swiper): void
}>()

const swiperElm = ref<HTMLDivElement>()
const paginationElm = ref<HTMLDivElement>()
const navigationPrevElm = ref<HTMLDivElement>()
const navigationNextElm = ref<HTMLDivElement>()

const swiperModules: SwiperModule[] = []
if (props.pagination) swiperModules.push(Pagination)
if (props.autoplay) swiperModules.push(Autoplay)
if (props.keyboard) swiperModules.push(Keyboard)
if (props.navigation) swiperModules.push(Navigation)

const initialized = ref(false)

const swiper = ref<Swiper>()
onMounted(() => {
  if (!swiperElm.value) return
  swiper.value = new Swiper(swiperElm.value, {
    ...props,
    modules: swiperModules,
    pagination: shouldCreateSlot(props.pagination, 'el')
      ? { ...props.pagination, el: paginationElm.value }
      : props.pagination,
    navigation: shouldCreateSlot(props.navigation, ['prevEl', 'nextEl'])
      ? {
          ...props.navigation,
          prevEl: navigationPrevElm.value,
          nextEl: navigationNextElm.value
        }
      : props.navigation,
    on: {
      init(s: Swiper) {
        initialized.value = true
        emit('init', s)
      },
      slideChange(s: Swiper) {
        emit('slideChange', s)
      }
    }
  })
})

onBeforeUnmount(() => {
  if (swiper.value && !swiper.value.destroyed) {
    swiper.value.destroy?.()
  }
})

defineExpose({ swiper })

const shouldCreateSlot = (
  prop: (typeof props)[keyof typeof props],
  properties: string | string[]
): prop is object => {
  if (!prop || typeof prop !== 'object') return false
  const propsToCheck = Array.isArray(properties) ? properties : [properties]
  return !propsToCheck.every(p => p in prop)
}

provide('swiper', swiper)
</script>

<style lang="scss" scoped>
.swiper {
  display: flex;
  flex-direction: column;
}

.controls {
  z-index: 2;
}
</style>
